<template>
  <v-dialog v-model="show" height="50%" width="70%" scrollable>
    <v-card>
      <v-card-title>
        <h2>Flag Reports</h2>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-simple-table height="600px">
          <thead>
            <tr>
              <th width="20%">Flagged by</th>
              <th width="20%">Flagged at</th>
              <th width="20%">Reason</th>
              <th width="40%">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="report in reports"
              :key="report.id"
              height="100px"
              class="ma-5"
            >
              <td>
                {{ report.reporter.username }}
              </td>
              <td>{{ addedDate(report.created_at) }}</td>
              <td>{{ report.reason }}</td>
              <td>{{ report.description }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-pagination
          v-if="listMeta && listMeta.last_page > 1"
          class="pt-4"
          :value="listMeta.current_page"
          :length="paginationLength"
          :total-visible="paginationLimit"
          @input="fetchReports"
        ></v-pagination>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from '@/services/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },

    user: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      show: this.value,
      isLoading: false,
      paginationLimit: 6,
      listMeta: null,
      reports: null
    }
  },

  computed: {
    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page + 1) | 0
    }
  },

  methods: {
    async fetchReports(page = 1) {
      if (!this.isLoading) {
        this.isLoading = true
        const { data } = await Api.get(`/flag-user/${this.user.id}/reports`, {
          params: { limit: 20, page: page }
        })

        this.listMeta = data.meta
        this.reports = data.data
        this.isLoading = false
      }
    },

    addedDate(date) {
      return dayjs
        .utc(date)
        .local()
        .format('MMM DD, YYYY hh:mm A')
    }
  },

  watch: {
    value(val) {
      this.show = val

      if (val === true) {
        this.fetchReports(1)
      }
    },

    show(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style></style>
