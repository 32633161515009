<template>
  <tr class="my-4 bordered">
    <td class="v-align-top">
      <div class="d-flex align-start pa-2">
        <div>
          <user-avatar
            :avatar="user.avatar"
            size="40"
            class="mr-20"
          ></user-avatar>
        </div>
        <div class="px-4">
          <div class="text-subtitle-1">
            <strong>
              {{ user.fullName }}
            </strong>
          </div>
          <div class="text-subtitle-2">
            {{ user.organisationName }}
          </div>
        </div>
      </div>
    </td>
    <td width="20%">
      <div class="d-flex">
        <v-btn text outlined color="warning" @click="showFlagList = true" dense>
          <v-icon left> mdi-flag-outline </v-icon>
          {{ user.reports_count }}
        </v-btn>
        <v-select
          outlined
          v-model="action"
          :items="actions"
          label="Take Action"
          dense
          class="ml-2"
          @change="openDialog(user)"
        ></v-select>
      </div>
    </td>
    <FlagList v-model="showFlagList" :user="user" />
    <ConfirmModal
      v-model="confirmDialog"
      :title="dialogTitle"
      :message="dialogMessage"
    >
      <v-btn text :loading="isLoading" @click="confirmDialog = false">
        Cancel
      </v-btn>
      <v-btn
        color="error"
        depressed
        :loading="isLoading"
        @click="onTakeActionClick"
      >
        Confirm
      </v-btn>
    </ConfirmModal>
  </tr>
</template>

<script>
import FlagList from './FlagList'
import ConfirmModal from '@/components/modals/ConfirmModal'
import Api from '@/services/api'
import UserAvatar from '@/components/UserAvatar'

export default {
  components: {
    FlagList,
    ConfirmModal,
    UserAvatar
  },
  props: {
    user: Object
  },
  data() {
    return {
      action: null,
      actions: [
        { text: 'Reviewed Okay', value: 'reviewed_okay' },
        { text: 'Delete User', value: 'user_deleted' }
      ],
      confirmDialog: false,
      showFlagList: false,
      isLoading: false
    }
  },
  computed: {
    dialogTitle() {
      return this.action === 'reviewed_okay'
        ? 'Remove User Report'
        : 'Delete User'
    },
    dialogMessage() {
      return this.action === 'reviewed_okay'
        ? 'Are you sure you want to remove the report for this user? Previously reported user will be able to access the app normally.'
        : 'Are you sure you want to delete this user? Deleting this user will not allow access to the app for this user. '
    }
  },
  methods: {
    async onTakeActionClick() {
      this.isLoading = true
      const form = new FormData()
      form.append('action', this.action)

      try {
        await Api.post(`flag-user/${this.user.id}/take-action`, form)
        this.isLoading = false
        this.confirmDialog = false
        this.$store.commit('user/removeUserFromList', this.user)
        this.showSuccessSnackbar()
      } catch (error) {
        this.isLoading = false
      }
    },

    showSuccessSnackbar() {
      this.$store.commit('callSnackbar', {
        state: true,
        text: 'Action has been taken!',
        color: 'green'
      })
    },

    openDialog(user) {
      this.user = user
      this.confirmDialog = !this.confirmDialog
    }
  }
}
</script>
