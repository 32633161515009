<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-semibold"
        >Flagged User</v-toolbar-title
      >
      <v-spacer />
    </v-app-bar>

    <div
      class="mt-12 mx-4"
      id="user-action-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disable="isLoading"
      inifinite-scroll-distance="100"
    >
      <v-simple-table>
        <action-card v-for="user in users" :user="user" :key="user.id" />
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mapActions, mapMutations, mapState } from 'vuex'
import infiniteScroll from 'vue-infinite-scroll'
import ActionCard from './components/ActionCard'

export default {
  name: 'FlaggedUserPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon,
    ActionCard
  },

  directives: {
    infiniteScroll
  },

  data: () => ({
    userAction: null,
    isLoading: false,
    showReports: false
  }),

  created() {
    this.clearUserList()
    this.fetchFlaggedUsers()
  },

  computed: {
    ...mapState({
      users: state => state.user.list,
      listMeta: state => state.user.listMeta
    })
  },

  methods: {
    ...mapActions({
      getFlaggedUsers: 'user/getFlaggedUsers'
    }),

    ...mapMutations({
      clearUserList: 'user/clearUserList'
    }),

    async fetchFlaggedUsers(page = 1) {
      this.showReports = false
      if (!this.isLoading) {
        let params = {
          page: page
        }

        this.isLoading = true
        await this.getFlaggedUsers(params)
        this.isLoading = false
      }
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchFlaggedUsers(this.listMeta.current_page + 1)
      }
    }
  }
}
</script>
