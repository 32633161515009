<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-semibold"
        >Flagged User Actions</v-toolbar-title
      >
      <v-spacer />
    </v-app-bar>

    <div
      class="mt-12 mx-4"
      id="user-action-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disable="isLoading"
      inifinite-scroll-distance="100"
    >
      <v-simple-table>
        <action-card
          v-for="userAction in userActions"
          :user-action="userAction"
          :key="userAction.id"
        />
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mapActions, mapMutations, mapState } from 'vuex'
import infiniteScroll from 'vue-infinite-scroll'
import { mdiVideo, mdiDotsHorizontal } from '@mdi/js'
import ActionCard from './components/ActionCard'

export default {
  name: 'FlaggedUserActionsPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon,
    ActionCard
  },

  directives: {
    infiniteScroll
  },

  data: () => ({
    userAction: null,
    isLoading: false,
    showFlagList: false,
    icons: {
      video: mdiVideo,
      option: mdiDotsHorizontal
    }
  }),

  created() {
    this.fetchUserActions()
  },

  computed: {
    ...mapState({
      userActions: state => state.userAction.list,
      listMeta: state => state.userAction.listMeta
    })
  },

  methods: {
    ...mapActions({
      getUserActions: 'userAction/getUserActions',
      getFlaggedUserActions: 'flaggedUserAction/getFlaggedUserActions'
    }),

    ...mapMutations({
      clearFlaggedUserActionList: 'flaggedUserAction/clearFlaggedUserActionList'
    }),

    async fetchUserActions(page = 1) {
      this.showFlagList = false
      if (!this.isLoading) {
        let params = {
          page: page
        }

        this.isLoading = true
        await this.getUserActions(params)
        this.isLoading = false
      }
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchUserActions(this.listMeta.current_page + 1)
      }
    },

    onTakeActionClick(userAction) {
      if (!this.isLoading) {
        let params = {
          page: 1,
          id: userAction.id
        }

        this.userAction = userAction
        this.isLoading = true
        this.clearFlaggedUserActionList()
        this.getFlaggedUserActions(params)
        this.isLoading = false
        this.showFlagList = true
      }
    }
  }
}
</script>
