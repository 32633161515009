<template>
  <v-dialog v-model="show" height="50%" width="70%" scrollable>
    <v-card>
      <v-card-title>
        <h2>Flag Reports</h2>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-simple-table height="600px">
          <thead>
            <tr>
              <th width="20%">Flagged by</th>
              <th width="20%">Flagged at</th>
              <th width="20%">Reason</th>
              <th width="40%">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="flaggedUserAction in flaggedUserActions"
              :key="flaggedUserAction.id"
              height="100px"
              class="ma-5"
            >
              <td>
                {{ flaggedUserAction.reporter.username }}
              </td>
              <td>{{ flaggedUserAction.addedDate }}</td>
              <td>{{ flaggedUserAction.reason }}</td>
              <td>{{ flaggedUserAction.description }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-pagination
          v-if="listMeta && listMeta.last_page > 1"
          class="pt-4"
          :value="listMeta.current_page"
          :length="paginationLength"
          :total-visible="paginationLimit"
          @input="fetchFlaggedUserActions"
        ></v-pagination>
      </v-card-text>
      <v-divider></v-divider>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green" text :loading="isLoading" @click="onApprove">
          Approve
        </v-btn>
        <v-btn color="red" text :loading="isLoading" @click="onDelete">
          Delete
        </v-btn>
      </v-card-actions> -->
    </v-card>

    <ConfirmModal
      v-model="confirmDialog"
      :title="confirm.title"
      :message="confirm.message"
    >
      <v-btn text :disabled="isLoading" :loading="isLoading" @click="onCancel"
        >No</v-btn
      >
      <v-btn text :disabled="isLoading" :loading="isLoading" @click="onConfirm"
        >Yes</v-btn
      >
    </ConfirmModal>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import ConfirmModal from '@/components/modals/ConfirmModal'
import Api from '@/services/api'

export default {
  components: {
    ConfirmModal
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },

    userAction: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      show: this.value,
      isLoading: false,
      confirmDialog: false,
      confirm: {
        action: null,
        title: '',
        message: ''
      },
      snackbar: {
        show: false,
        message: null,
        color: null
      },
      paginationLimit: 6
    }
  },

  computed: {
    ...mapState({
      flaggedUserActions: state => state.flaggedUserAction.list,
      listMeta: state => state.flaggedUserAction.listMeta
    }),

    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page + 1) | 0
    }
  },

  methods: {
    ...mapActions({
      getFlaggedUserActions: 'flaggedUserAction/getFlaggedUserActions',
      removeUserAction: 'userAction/removeUserAction'
    }),

    ...mapMutations({
      clearUserActions: 'userAction/clearUserActionList',
      clearFlaggedUserActions: 'flaggedUserAction/clearFlaggedUserActionList',
      callSnackbar: 'callSnackbar'
    }),

    onApprove() {
      this.confirmDialog = true
      this.confirm = {
        action: 'reviewed_okay',
        title: 'Approve User Action',
        message: 'Are you sure to approve the flagged user action?'
      }
    },

    onDelete() {
      this.confirmDialog = true
      this.confirm = {
        action: 'user_action_deleted',
        title: 'Delete User Action',
        message: 'Are you sure to delete the flagged user action?'
      }
    },

    async onConfirm() {
      this.confirmDialog = false
      this.isLoading = true

      let params = {
        action: this.confirm.action
      }

      await Api.post(`user-action/${this.userAction.id}/take-action`, params)
        .then(res => {
          this.removeUserAction(this.userAction.id)
          this.callSnackbar({
            state: true,
            text: 'Action Taken',
            color: 'green'
          })
        })
        .catch(e => {
          this.callSnackbar({
            state: true,
            text: 'Ops! Something went wrong',
            color: 'red'
          })
        })

      this.isLoading = false
      this.show = false
    },

    onCancel() {
      this.confirmDialog = false
      this.confirm = {
        action: null,
        title: '',
        message: ''
      }
    },

    async fetchFlaggedUserActions(page = 1) {
      if (!this.isLoading) {
        let params = {
          id: this.userAction.id,
          page
        }

        this.clearFlaggedUserActions()
        this.isLoading = true
        await this.getFlaggedUserActions(params)
        this.isLoading = false
      }
    }
  },

  watch: {
    value(val) {
      this.show = val

      if (val === true) {
        this.fetchFlaggedUserActions(1)
      }
    },

    show(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style></style>
