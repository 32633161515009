<template>
  <tr class="my-4 bordered">
    <td class="v-align-top">
      <div class="d-flex align-start pa-2">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card flat>
              <v-img
                class="elevation-2 rounded"
                :src="userAction.video.thumbnail"
                height="60px"
                width="60px"
                cover
              >
              </v-img>
              <v-fade-transition>
                <v-overlay v-if="hover" absolute color="#036358">
                  <v-btn icon target="_blank" :href="userAction.video.url">
                    <v-icon>mdi-play</v-icon>
                  </v-btn>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
        <div class="px-4">
          <div class="text-subtitle-1">
            <strong>
              {{ userAction.mission.name }}
            </strong>
          </div>
          <div class="text-subtitle-2">
            {{ userAction.challenge.title }}
          </div>
        </div>
      </div>
    </td>
    <td class="v-align-top pa-2">
      <div class="text-subtitle-2">
        <strong>{{ userAction.user.username }}</strong> -
        {{ userAction.fromNow }}
      </div>
      <div></div>
      {{ userAction.caption }}
    </td>
    <td width="1%">
      <div class="d-flex">
        <v-btn text outlined color="warning" @click="showFlagList = true">
          <v-icon left> mdi-flag-outline </v-icon>
          {{ userAction.reports_count }}
        </v-btn>
        <v-btn
          text
          outlined
          color="error"
          @click="confirmDialog = !confirmDialog"
          class="ml-2"
        >
          <v-icon left> mdi-undo-variant </v-icon>
          Un-delete
        </v-btn>
      </div>
    </td>
    <FlagList v-model="showFlagList" :userAction="userAction" />
    <ConfirmModal
      v-model="confirmDialog"
      title="Un-delete user action"
      message="Are you sure you want to restore this user action?"
    >
      <v-btn text :loading="isLoading" @click="confirmDialog = false">
        Cancel
      </v-btn>
      <v-btn
        color="error"
        depressed
        :loading="isLoading"
        @click="onUndeleteConfirm"
      >
        <v-icon left> mdi-undo-variant </v-icon>
        Un-delete
      </v-btn>
    </ConfirmModal>
  </tr>
</template>

<script>
import FlagList from './FlagList'
import ConfirmModal from '@/components/modals/ConfirmModal'
import Api from '@/services/api'

export default {
  components: {
    FlagList,
    ConfirmModal
  },
  props: {
    userAction: Object
  },
  data() {
    return {
      confirmDialog: false,
      showFlagList: false,
      isLoading: false
    }
  },
  methods: {
    async onUndeleteConfirm() {
      this.isLoading = true
      try {
        await Api.patch(`user-action/${this.userAction.id}/restore`)
        this.isLoading = false
        this.confirmDialog = false
        this.$store.commit('userAction/removeUserAction', this.userAction)
        this.$emit('restored')
        this.showSuccessSnackbar()
      } catch (error) {
        this.isLoading = false
      }
    },
    showSuccessSnackbar() {
      this.$store.commit('callSnackbar', {
        state: true,
        text: 'User action restored!',
        color: 'green'
      })
    }
  }
}
</script>
